details {
  height: var(--collapsed);
  overflow: hidden;
  transition: height 0.3s ease-out;
}

details[open] {
  height: var(--expanded);
}

summary {
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }
}
