.header {
  line-height: 1.1;
  position: relative;
}

.header--home {
  background: url(./static/assets/burkhardt.jpg) no-repeat center 25%;
  background-size: cover;
  min-height: 100vh;
  margin-bottom: 4rem;
}

.header:not(.header--home) {
  margin-bottom: 15rem;
}

.header__logo {
  display: block;
  padding: 1rem 0;

  & img {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    max-width: 30rem;
  }
}

.header__claim {
  color: var(--blue);
  font-size: var(--fs-large);
  font-weight: bold;
}

.header__name {
  margin-top: 20rem;
  font-size: var(--fs-large);
  display: inline-block;
  text-align: right;
}

.header__title {
  color: var(--blue);
  font-size: var(--fs-big);
  margin-top: 1rem;
  text-align: center;
}

.header__button {
  --_size: 7.5em;

  position: absolute;
  bottom: -5rem;
  left: 5rem;

  display: grid;
  place-items: center;
  text-align: center;
  font-size: var(--fs-larger);
  font-weight: bold;

  color: #fff;
  background: var(--blue);
  border-radius: 99rem;
  width: var(--_size);
  height: var(--_size);

  transform: rotate(-4deg);
  backface-visibility: hidden;
}

.header__button::after {
  content: "";
  background-image: url("./button.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 8rem 8rem;
  width: 1.5em;
  height: 1.5em;
  font-size: 3rem;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 0.8em);
  transition: transform 0.3s;
  display: grid;
  place-content: center;
}

.header__button:hover::after {
  transform: scale(1.15);
}

@media (--media-md) {
  .header--home {
    background-image: url(./static/assets/burkhardt-wide.jpg);
    min-height: auto;
    aspect-ratio: 2.1/1;
  }

  .header:not(.header--home) {
    margin-bottom: 5rem;
  }

  .header__logo {
    & img {
      max-width: 15rem;
    }
  }

  .header__name {
    margin-top: 20%;
  }

  .header__title {
    position: absolute;
    bottom: -0.75em;
    left: 1rem;
    right: 1rem;
    text-align: center;
  }

  .header__button {
    bottom: 30%;
    left: 60%;
    font-size: var(--fs-large);

    &::after {
      font-size: 2rem;
      background-size: 5rem 5rem;
      height: 1.75em;
    }
  }
}

@media (--media-lg) {
  .header__logo {
    padding-top: 2rem;
    & img {
      max-width: 30rem;
    }
  }

  .header__claim {
    font-size: var(--fs-large);
  }

  .header__name {
    margin-top: 20%;
    font-size: var(--fs-large);
  }

  .header__button {
    bottom: 50%;
    font-size: var(--fs-larger);
    &::after {
      font-size: 3rem;
      background-size: 8rem 8rem;
      height: 1.5em;
    }
  }
}

@media (--media-xl) {
  .header--home {
    .container {
      max-width: 130rem;
    }
  }
}

@media (--media-sm-only) {
  .header--home {
    margin-bottom: 10rem;
  }

  .header__button {
    bottom: -12rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
