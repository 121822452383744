html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  position: relative;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: var(--brown);
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    135deg,
    rgba(190, 223, 245, 0.8),
    #fff 30%,
    #fff 70%,
    rgba(190, 223, 245, 0.8)
  );
}

.body {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  margin-top: -4em;
}

.main {
  flex: 1 0 auto;
}

.container-region-inner {
  max-width: 50rem;
  margin: 0 auto;
}

.container-region-outer {
  margin: 0 auto;
  background: linear-gradient(
    135deg,
    rgba(190, 223, 245, 0.8),
    #fff 30%,
    #fff 70%,
    rgba(190, 223, 245, 0.8)
  );

  padding-block: 4rem;

  & h2:first-child {
    text-align: center;
    font-size: var(--fs-larger);
  }

  &:first-of-type h2:first-child {
    margin-top: 10rem;
  }
}

@media (--media-sm-only) {
  .body {
    margin-top: -15rem;
  }

  .container-region-outer:first-of-type {
    padding-top: 10rem;
  }
}

@media (--media-md) {
  .container-region-outer {
    &:first-of-type h2:first-child {
      margin-top: 5rem;
    }
  }
}
