.f3cc-embed[data-provider="vimeo"],
.f3cc-embed[data-provider="youtube"],
.responsive-embed {
  aspect-ratio: 16 / 9;
}

.responsive-embed {
  position: relative;
  overflow: hidden;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.f3cc-embed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
