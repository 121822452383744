.reasons {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  margin: 2rem 0;
  padding: 0 1rem;
}

.reasons__reason {
  --icon-size: 4em;
  --_p: 1.5em;

  box-shadow: var(--shadow);
  background: #fff;
  border-radius: 20px;
  position: relative;

  margin: calc(0.5 * var(--icon-size)) 0 0;
  padding: calc(0.75 * var(--icon-size)) var(--_p) var(--_p) var(--_p);
}

.reasons__reason:nth-child(3) img {
  transform: scale(1.183);
}

.reasons__icon {
  background: var(--beige);
  border-radius: 16px;
  width: var(--icon-size);
  height: var(--icon-size);
  padding: 1em;
  position: absolute;

  left: var(--_p);
  top: calc(-0.5 * var(--icon-size));
}

.reasons__reason h2 {
  color: var(--blue);
  font-weight: normal;
  margin-bottom: 0.5rem;

  & em {
    font-style: normal;
    font-weight: bold;
  }
}

@media (--media-md) {
  .reasons__reason {
    --icon-size: 5em;
    margin: 0 0 0 calc(0.5 * var(--icon-size));
    padding: var(--_p) var(--_p) var(--_p) calc(0.75 * var(--icon-size));
  }

  .reasons__icon {
    left: calc(-0.5 * var(--icon-size));
    top: var(--_p);
  }
}
