.form {
  --_hp: 1rem;
  max-width: 50rem;
  margin: 0 auto 4em;
  padding: 0 1rem;

  .widget--radioselect > div,
  .widget--checkboxselectmultiple > div {
    list-style: none;
    padding: 0;
  }

  .errors {
    display: flex;
    align-items: center;
    line-height: 1.3;

    margin: 1rem 0;
    margin-bottom: 1rem;

    color: var(--error-fg);
  }

  .errorlist {
    color: var(--error-fg);
    padding: 0 var(--_hp);
  }

  .buttons {
    margin: 1rem 0;
  }
}

.container-form {
  background: rgba(190, 223, 245, 0.8);
}

.cover__container {
  margin-top: 5rem;
}

.field {
  position: relative;
  padding: 0.5rem 0;

  &:not(.widget--checkboxinput) > label {
    display: inline-block;
    font-weight: bold;
  }

  > label.required::after {
    content: " *";
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="number"],
  & input[type="date"],
  & select,
  & textarea,
  & .grow-wrap > textarea,
  & .grow-wrap::after {
    display: block;
    width: 100%;
    border: none;
    border-radius: 0.75rem;
    padding: 1rem var(--_hp);
    margin: 0.25rem 0;
    background: #fff;

    &:focus-visible {
      outline: 2px solid var(--blue);
    }
  }
}

.field input::placeholder {
  color: #889;
}

.field.is-invalid {
  & input[type="text"],
  & input[type="email"],
  & input[type="number"],
  & input[type="date"],
  & select,
  & textarea {
    border-color: var(--error-fg);
  }
}

.widget--select select {
  appearance: none;
}

.widget--splitdatewidget {
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: 1fr 1fr 2fr;

  & label {
    grid-column: 1 / -1;
  }

  & input {
    flex: 1 1 30%;
  }
}

.field__help {
  display: inline-block;
  padding: 0.25rem 0 0;
}

.widget--radioselect {
  > div {
    display: flex;
    gap: 1rem 1.5rem;
    flex-flow: row wrap;
    margin: 1rem 0;
  }

  & input {
    display: none;
  }

  & div label {
    position: relative;
    cursor: pointer;

    background: url(radio_button_unchecked.svg) no-repeat 0 center;
    background-size: contain;

    padding-left: 1.75em;

    height: 1.5rem;
    display: flex;
    align-items: center;
  }

  & div input:checked + label {
    background-image: url(radio_button_checked.svg);
  }
}

.widget--hiddeninput,
.widget--recaptchav3 > label {
  display: none !important;
}
