:root {
  --black: #333;
  --white: #fff;

  --brown: #d5b9a2;
  --blue: #0090df;
  --brown: #63554b;
  --beige: #d5b9a2;

  --error-fg: #c6232e;
  --warning: orange;
  --info: darkblue;
  --success: darkgreen;

  --border: 1px solid var(--fg);
  --shadow: 0 0 60px 0 #63554b1a;
  --radius: 1.25rem;

  --z-top: 5;
  --z-menu: 3;

  /* Font sizes */
  --fs-small: 14px;
  --fs-large: 20px;
  --fs-larger: 32px;
  --fs-big: 54px;
}

@media (--media-md) {
  :root {
    --fs-big: 80px;
  }
}

@media (--media-lg) {
  :root {
    --fs-big: 120px;
    --fs-large: 24px;
  }
}
