/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./open-sans-v34-latin-regular.woff2") format("woff2");
}

/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./open-sans-v34-latin-700.woff2") format("woff2");
}

/* open-sans-500italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src: local(""), url("./open-sans-v34-latin-500italic.woff2") format("woff2");
}

/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local(""), url("./open-sans-v34-latin-700italic.woff2") format("woff2");
}
