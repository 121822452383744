.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1rem;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  font-size: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
}

.button {
  user-select: none;
  line-height: 1;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: #fff;
  background: var(--blue);
  padding: 0.75rem 1rem;
  border-radius: 12px;
}

.button--submit {
}
