.faq {
  border-radius: var(--radius);
  padding: 2rem 1rem;

  > .cell {
    grid-column: 2 / span 10;

    @media (--media-md) {
      grid-column: 3 / span 8;
    }
  }
}

.faq__item {
  background: #fff;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
}

.faq__question {
  padding: 4rem 2rem 1rem;
  cursor: pointer;
  font-weight: bold;
  position: relative;
}

.faq__counter {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  font-size: var(--fs-large);
}

.faq__question .icon {
  position: absolute;
  background: var(--blue);
  color: #fff;
  border-radius: 99px;
  right: 2rem;
  top: 1.5rem;
  font-size: 2rem;
  transition: transform 0.3s ease-in-out;
  transform: rotate(-45deg);
}

.faq__q {
  font-size: var(--fs-large);
}

.faq__item[open] .faq__question .icon {
  transform: rotate(90deg);
}

.faq__item + .faq__item {
  margin-top: 1rem;
}

.faq__counter {
  color: var(--blue);
}

.faq__answer {
  padding: 0 2rem 1rem;
  overflow-wrap: break-word;
  background-color: var(--white);

  & p {
    margin-bottom: 0.5rem;
  }

  & ul:first-child {
    margin-top: 0;
  }

  & li {
    margin-bottom: 0.5rem;
  }
}

@media (--media-md) {
  .faq__question {
    padding: 1.5rem 6rem;
  }

  .faq__answer {
    padding: 0 6rem 1.5rem;
  }
}
