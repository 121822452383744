.footer {
  background: var(--blue);
  color: var(--white);
  padding: 2.5rem 1rem 1rem;
  position: relative;
  font-size: 16px;

  & a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & strong {
    display: block;
    color: #fff;
  }

  & address {
    font-style: normal;
  }

  & .icon {
    --icon-size: 2em;
    height: var(--icon-size);
    width: var(--icon-size);
  }
}

.footer__container {
  max-width: 50rem;
}

.footer__parts {
  gap: 1rem;
}

.footer__address {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.5rem;
}

.footer__address-address {
  flex: 2 0 auto;
}

@media (--media-md) {
  .footer__address-contacts {
    text-align: right;
  }
}

.footer__the-end {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap-reverse;
  margin-top: 0;
  padding: 0;
}

.footer__nav-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.footer__menu {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .active {
    text-decoration: underline;
  }
}

.footer__icon-link {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

@media (--media-sm-only) {
  .footer__parts {
    flex-direction: column;
  }
}

@media (--media-md) {
  .footer__parts {
    flex-direction: column;
  }

  .footer__the-end {
    margin-top: 1rem;
    padding: 1rem 0;
  }

  .footer__nav-container {
    margin-top: 0;
  }
}
