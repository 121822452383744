:root {
  --grid-columns: 12;
  --grid-gutter: 1.5rem;
}

.container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 calc(0.5 * var(--grid-gutter));

  .header:not(.header--home) + .body &,
  header:not(.header--home) & {
    max-width: 50rem;
    margin-bottom: 1em;
  }
}

.header:not(.header--home) + .body .container {
  max-width: 50rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
}

.cell {
  --width-sm: var(--grid-columns); /* Default width */
  --width: var(--width-sm);
  grid-column-end: span var(--width);
}

.grid--gapped {
  gap: var(--grid-gutter);
}

.grid--padded > :where(.cell) {
  padding: calc(0.5 * var(--grid-gutter));
}

.grid--revert {
  margin: calc(-1 * var(--grid-gutter));
}

@media (--media-md) {
  .cell {
    --width-md: var(--width-sm);
    --width: var(--width-md);
  }
}

@media (--media-lg) {
  .cell {
    --width-lg: var(--width-md);
    --width: var(--width-lg);
  }
}

@media (--media-xl) {
  .cell {
    --width-xl: var(--width-lg);
    --width: var(--width-xl);
  }
}

@for $i from 1 to 12 {
  .cell.sm-$i {
    --width-sm: $i;
  }
  .cell.md-$i {
    --width-md: $i;
  }
  .cell.lg-$i {
    --width-lg: $i;
  }
  .cell.xl-$i {
    --width-xl: $i;
  }
}
