.prose a {
  color: var(--blue);
  text-decoration: none;
  overflow-wrap: break-word;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

.prose figure img {
  margin: 0 auto;
}

.prose h1 {
  overflow-wrap: break-word;
  hyphens: auto;
}

.prose h2,
.prose h3 {
  color: var(--blue);
  text-align: left;
}

.prose h2 {
  margin-top: 0.8em;
  margin-bottom: 0.2em;
}

.prose h3 {
  margin-top: 0.6em;
  margin-bottom: 0.2em;
}

.prose p {
  margin-block: 0.4em;
}

.prose ul {
  margin-top: 0.1em;
  margin-bottom: 0.5em;
}
