body {
  --f3cc-background: #666;
  --f3cc-foreground: var(--white);
  --f3cc-button-background: #888;
  --f3cc-accept-background: var(--blue);
}

.f3cc {
  box-shadow: var(--shadow);
}
